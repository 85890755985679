.page-container-default {
	grid-column: 1 / 21;
	grid-row: 1 / 21;
	padding: 10px;
	min-height: 100%;
}

.onboarding-helper {
	position: absolute;
	border-radius: 10px;
	padding: 30px;
	box-shadow: 4px 4px 10px #505050;
	z-index: 2;
	background-color: #ffffff;
	font-weight: normal;
}

.onboarding-helper-arrow::after {
	background-color: #ffffff;
	content: "\00a0";
	display: block;
	position: absolute;
	height: 30px;
	width: 30px;
	transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.left.onboarding-helper::after {
	box-shadow: -2px 2px 2px 0 #50505040;
	border-radius: 0 0 0 5px;
	left: -15px;
	top: 50%;
	margin-top: -15px;
}

.right.onboarding-helper::after {
	box-shadow: 4px -4px 4px 0 #50505080;
	border-radius: 0 5px 0 0;
	right: -15px;
	top: 50%;
	margin-top: -15px;
}
.bottom.onboarding-helper::after {
	box-shadow: 4px 4px 4px 0 #50505080;
	border-radius: 0 0 5px 0;
	left: 50%;
	bottom: -15px;
	margin-left: -15px;
}
.top.onboarding-helper::after {
	box-shadow: -2px -2px 3px -1px #50505040;
	border-radius: 5px 0 0 0;
	left: 50%;
	top: -15px;
	margin-left: -15px;
}

.ob-start {
	top: 25px;
	left: 465px;
	width: 370px;
}

.ob-after-members {
	top: 83px;
	left: 465px;
	width: 400px;
}

.ob-after-properties {
	top: 125px;
	left: 465px;
	width: 400px;
}

.ob-after-all {
	top: 15vh;
	left: calc(50% - 250px);
	width: 500px;
}

@media (max-width: 1119px) {
	.onboarding-helper {
		display: none;
	}

	.onboarding-helper-arrow {
		display: none;
	}
}
