input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

body {
	scrollbar-width: thin;
	scrollbar-color: #acacac #f1f1f1;
}

/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #acacac;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #999999;
	border-radius: 5px;
}

::-webkit-scrollbar-track-piece {
	background-color: #f1f1f1;
	-webkit-border-radius: 4px;
}

.react-pdf__Page__canvas {
	margin: 0 auto;
	width: 80% !important;
	height: 100% !important;
	padding: auto;
}

.Toastify__toast-container {
	bottom: 0px;
	z-index: 10003;
}

.Toastify__toast > button > svg {
	display: none;
}
.Toastify__toast {
	border-radius: 10px;
}

/* modal */

.modal {
	text-align: center;
	/* display: none; Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: hidden; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
	contain: content;
	background-color: #fefefe;
	margin: 5% auto; /* 15% from the top and centered */
	padding: 20px;
	align-items: center;
	border: 1px solid #888;
	width: 80%; /* Could be more or less, depending on screen size */
}

.modal-content-small {
	contain: content;
	background-color: #fefefe;
	margin: 5% auto; /* 15% from the top and centered */
	padding: 20px;
	align-items: center;
	border: 1px solid #888;
	width: 50%; /* Could be more or less, depending on screen size */
}

.modal-display-none {
	display: none;
}

.modal-display-block {
	display: block;
}

/* The Close Button */
.modal-close {
	position: fixed;
	color: #aaa;
	top: 20px;
	right: 30px;
	font-size: 28px;
	font-weight: bold;
}

.modal-block {
	display: block;
}

.modal-hidden {
	display: none;
}

.modal-close:hover,
.modal-close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

/* other class */

.flex-dir-column {
	flex-direction: column;
}

.flex-dir-row {
	flex-direction: row;
}

.padding-t-b {
	padding: 15px 0 15px 0;
}

.content-background-box {
	max-height: 70vw;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	width: auto;
	height: auto;
	text-align: center;
	background-color: #d6d5d565;
	color: rgb(0, 0, 0);
	padding: 10px;
	border-radius: 10px;
	border: solid #50505030;
	border-width: 1px;
	box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
}

.content-background-box-row {
	display: flex;
	flex-direction: row;
	min-height: 100%;
	width: auto;
	height: 40vh;
	text-align: center;
	background-color: #d6d5d565;
	color: rgb(0, 0, 0);
	padding: 10px;
	border-radius: 10px;
	border: solid #50505030;
	border-width: 1px;
	box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
}

.calculation-box {
	height: 75px;
	width: 150px;
	position: absolute;
	bottom: 40px;
	left: 10px;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 15px;
	text-align: center;
}

p {
	margin: 0;
	font-size: 16px;
}

.mapboxgl-control-container {
	position: absolute;
	top: 200px;
}
