.bulk-cell {
	padding: 0;
	min-width: 36px;
	height: 42px;
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	padding-left: 2px;
}

input,
select {
	border: 0;
}

th {
	border-right: 0;
	border-left: 0;
}

.first-cell {
	border-bottom: 1px solid black;
	text-align: center;
	padding: 0;
}

.icon-cell {
	padding: 0;
}

.bulk-row {
	padding: 0;
	margin: 0;
	height: 42px;
	justify-content: center;
}

.bulk-input {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	transform: translateX(-2px);
}

.bulk-select {
	box-sizing: content-box;
	border-bottom: 1px solid black;
	border-right: 1px solid black;
}
