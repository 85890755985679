* {
	font-family: "Poppins";
}

:root {
	--forest-color: #7fc646;
	--rock-color: #505050;
	--lake-color: #22c3f3;
	--field-color: #fead2d;
	font-family: "Poppins";
}

body {
	font-family: "Poppins";
}

html {
	overflow: hidden;
}

.grid-container {
	display: grid;
	height: 100vh;
	width: 100vw;
	grid-template-columns: 90px auto;
	grid-template-rows: auto;
	overflow: hidden;
}

.grid-topNavBar {
	display: none;
	grid-row: 1;
	grid-column: 1;
}

.grid-sideNavBar {
	grid-column: 1 / 2;
	grid-row: 1;
}

.grid-pageContent {
	grid-column: 2 / 3;
	grid-row: 1;
	z-index: 11;
	width: calc(100vw - 90px);
}

.content-box {
	display: flex;
	/* min-height: 100%; */
	height: calc(100% - 20px);
	text-align: center;
	justify-content: center;
	background-color: #fafafa;
	color: rgb(0, 0, 0);
	padding: 10px;
	border-radius: 10px;
	border: solid #50505030;
	border-width: 1px;
	box-shadow: 1px 1px 5px rgba(0 0 0 / 20%);
}

.content-box-only-style {
	background-color: #fafafa;
	color: rgb(0, 0, 0);
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 10px;
	border: solid #50505030;
	border-width: 1px;
	box-shadow: 1px 1px 5px rgba(0 0 0 / 20%);
}

/* .fit-heigth {
  height: fit-content;
} */

.page-content-page {
	z-index: 3;
	display: none;
}

.page-content-grid {
	display: grid;
	grid-template: repeat(20, 1fr) / repeat(20, 1fr);
	z-index: 3;
	height: 100vh;
	width: calc(100vw - 90px);
	font-family: "Poppins";
}

.map-grid-container {
	grid-column: 10 / 21;
	grid-row: 1 / 21;
	padding: 10px;
}

.map-grid-container-full {
	grid-column: 1 / 21;
	grid-row: 1 / 21;
	padding: 10px;
}

.page-container-default {
	grid-column: 1 / 21;
	grid-row: 1 / 21;
	/* grid-column: 1 / 20;
  grid-row: 1 / 20; */
	/* padding: 10px 10px 10px 10px; */
}

.page-container-with-map {
	grid-column: 1 / 10;
	grid-row: 1 / 21;
	padding: 10px;
}

a {
	/* color: #92badd; */
	display: inline-block;
	text-decoration: none;
	font-weight: 400;
}

h2 {
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	display: inline-block;
	margin: 20px 8px 10px 8px;
	color: #cccccc;
}

@media screen and (max-width: 1119px) {
	.map-grid-container-full {
		grid-column: 1 / 21;
		grid-row: 1 / 21;
		padding: 0;
	}

	.grid-topNavBar {
		display: block;
		grid-row: 1;
		grid-column: 1;
	}

	.grid-sideNavBar {
		display: none;
	}

	.page-container-default {
		padding: 0;
	}

	.grid-pageContent {
		grid-row: 2;
		grid-column: 1 /3;
		z-index: 12;
		/* overflow-y: auto; */
		overflow-y: hidden;
		width: auto;
	}

	.grid-container {
		display: grid;
		height: 100vh;
		width: 100vw;
		/* grid-template-columns: auto; */
		grid-template-rows: max(50px, 9vh) auto;
		/* overflow-y: auto; */
	}

	.page-content-grid {
		display: grid;
		grid-template: repeat(20, 1fr) / repeat(20, 1fr);
		z-index: 1;
		height: calc(100vh - 50px);
		width: 100vw;
		font-family: "Poppins";
	}

	.map-grid-container {
		grid-column: 1 / 21;
		grid-row: 1 / 13;
		width: 100%;
		padding-right: 10px;
	}

	.page-container-with-map {
		grid-column: 1 / 21;
		grid-row: 13 / 20;
		height: fit-content;
	}
}

@media screen and (max-width: 840px) {
	.map-grid-container {
		padding-right: 20px;
	}

	.page-container-with-map {
		padding-right: 20px;
	}
}

@media screen and (max-width: 768px) {
	.grid-container {
		display: grid;
		grid-template-columns: 0vh auto;
		/* grid-template-rows: max(50px,9vh) auto; */
	}

	.grid-sideNavBar {
		display: none;
	}

	.grid-pageContent {
		grid-row: 2;
		grid-column: 2;
		z-index: 12;
		/* overflow-y: auto; */
		/* overflow: hidden; */
	}

	.grid-topNavBar {
		grid-row: 1;
		grid-column: 1;
	}

	.page-content-grid {
		grid-column: 1 / 21;
		grid-row: 13 / 21;
		height: calc(100vh - 50px);
	}

	.map-grid-container {
		grid-column: 1 / 21;
		grid-row: 1 / 13;
		width: 100%;
		padding: 10px;
	}

	.page-container-with-map {
		grid-column: 1 / 21;
		grid-row: 13 / 20;
		width: 100%;
		padding: 10px;
	}
}
