.map-units-input-box {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.map-units-input-field {
	border: solid 1px;
	border-radius: 5px;
	width: 100%;
	margin-left: 5px;
}

.deafult-cursor {
	cursor: default;
}

.simple-import-map-container {
	overflow-y: hidden;
	overflow-x: hidden;
	border-radius: 10px;
	border: solid #50505030;
	border-width: 1px;
	box-shadow: 1px 1px 5px rgba(0 0 0 / 20%);
	height: 100%;
	position: relative;
}
