/* 
  Perustyylit ja resetit 
  ======================
  Fontti, autofill-poisto 
*/
:root {
	font-family: "Poppins", sans-serif;
	--toastify-toast-width: 320px; /* Toastin leveys */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: #000 !important;
}

/* 
	login-page 
	==========
	Kokonaisvaltainen kontti, 
	jossa taustakuva ja oranssi tausta 
  */
.login-page {
	width: 100%;
	height: 100vh;
	min-width: 320px; /* estää liiallisen kutistumisen */
	position: relative;
	overflow: hidden; /* ei skrollia sisäisesti */
	background-color: #fead2d; /* Pellon keltainen */
	background-image: url("../../resources/common/verkkopalvelu-footer.svg");
	background-repeat: no-repeat;
	background-position: bottom center;
	background-attachment: fixed;
	background-size: 100% auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start; /* logo ja otsikko ylös */
}

/* 
	Logo 
	====
	Kiinteät pikselimitat 
  */
.login-page-logo {
	width: 200px;
	height: 80px;
	background-image: url("../../resources/common/tievahti-logo-vaaka.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin-top: 40px;
}

/* 
	Otsikko 
	=======
	Valkoinen iso teksti, ei rivity
  */
.login-page-title h1 {
	margin-top: 5px;
	font-size: 2.5rem;
	font-weight: 900;
	color: white;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* 
	forms-container 
	===============
	Login- ja Register-lomakkeet vierekkäin 
	tai kapealla näytöllä päällekkäin
  */
.forms-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 700px;
	max-width: 90%;
	margin-top: 5px;
}

@media screen and (max-width: 900px) {
	.forms-container {
		flex-direction: column;
		align-items: center;
		margin-top: 30px;
		width: 90%;
	}
}

/* 
	LOGIN-FORM TYYLIT 
	=================
  */
.login-form {
	width: 48%;
	background-color: #fafafa;
	padding: 15px;
	border-radius: 10px 0 0 10px;
	box-shadow: -5px 10px 20px 5px rgba(0, 0, 0, 0.39);
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
}

.loginFormTitle {
	font-size: 1rem;
	font-weight: 700;
	color: #505050;
	margin-bottom: 10px;
	text-align: center;
}

.loginFormInput {
	width: 90%;
	padding: 8px;
	margin: 6px 0;
	border: 1px solid #ccc;
	border-radius: 8px;
	font-size: 0.85rem;
	color: #333;
	text-align: center;
}

.loginFormButton {
	width: 95%;
	padding: 8px;
	background-color: #7fc646; /* Metsän vihreä */
	color: #fff;
	font-size: 0.75rem;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.2s;
	margin-top: 6px;
	text-transform: uppercase;
}

.loginFormButton:hover {
	background-color: #75b23f;
}

.login-form-footer {
	margin-top: 8px;
	text-align: center;
	font-size: 0.8rem;
	color: #505050;
}

.login-form-footer a,
.login-form-footer span {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

.login-form-footer a:hover,
.login-form-footer span:hover {
	text-decoration: underline;
	color: #22c3f3;
}

/* 
	REGISTER-FORM TYYLIT 
	=====================
  */
.register-form {
	width: 48%;
	background-color: #505050; /* Kallion harmaa */
	padding: 15px;
	border-radius: 0 10px 10px 0;
	box-shadow: 5px 10px 20px 5px rgba(0, 0, 0, 0.39);
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
}

.registerFormTitle {
	font-size: 1rem;
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 10px;
	text-align: center;
}

.registerFormInput {
	width: 90%;
	padding: 8px;
	margin: 6px 0;
	border: 1px solid #ccc;
	border-radius: 8px;
	font-size: 0.85rem;
	text-align: center;
	color: #333;
	background-color: #fafafa;
}

.registerFormButton {
	width: 95%;
	padding: 8px;
	background-color: #7fc646;
	color: #fff;
	font-size: 0.75rem;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	margin-top: 6px;
	text-transform: uppercase;
	transition: background-color 0.2s;
}

.registerFormButton:hover {
	background-color: #75b23f;
}

/* terms-checkbox: Checkbox + teksti */
.terms-checkbox {
	font-size: 0.9rem;
	line-height: 1.3;
	color: #ffffff;
}

.terms-checkbox .MuiSvgIcon-root {
	font-size: inherit;
	vertical-align: middle;
	margin-left: 3px;
}

.terms-checkbox .MuiCheckbox-root {
	color: #ffffff;
}

/* 
	FORGOT-PASSWORD-DIALOG TYYLIT 
	=============================
	Sovellettu MUI Dialog: leveys, suljenappi
  */
.forgot-password-dialog-content {
	width: 400px;
	background-color: #fafafa;
	border-radius: 10px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
	position: relative;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	overflow: hidden;
}

.close-dialog-button {
	background: none;
	color: #505050;
	border: none;
	border-radius: 8px;
	padding: 5px 10px;
	cursor: pointer;
	font-size: 1.2rem;
	font-weight: 700;
	position: absolute;
	top: 10px;
	right: 10px;
}

.close-dialog-button:hover {
	color: #75b23f;
}

.forgot-password-title {
	font-size: 1rem;
	font-weight: 700;
	color: #505050;
	text-align: center;
	margin-top: 20px;
}

.forgot-password-textfield .MuiOutlinedInput-root {
	font-size: 0.85rem;
	border-radius: 8px;
}

.forgot-password-button {
	background-color: #7fc646;
	color: #fff;
	text-transform: uppercase;
	font-size: 0.75rem;
	padding: 10px;
	border-radius: 8px;
	border: none;
	cursor: pointer;
	transition: background-color 0.2s;
	width: 100%;
	align-self: center;
}

.forgot-password-button:hover {
	background-color: #75b23f;
}

/* 
	ChangePasswordForm 
	==================
	Valkoinen laatikko, samaa tyyliä kuin login-lomake
  */
.change-password-container {
	margin-top: 10vh;
	max-width: 350px;
	padding: 15px;
	background: #fafafa;
	border-radius: 10px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.change-password-title {
	font-size: 1rem;
	font-weight: 700;
	margin-top: auto;
	margin-bottom: 15px;
	text-align: center;
	color: #505050;
	display: flex;
	justify-content: center;
}

.change-password-input {
	width: 100%;
	margin-bottom: 10px;
}

.change-password-button {
	width: 100%;
	padding: 8px;
	background-color: #7fc646;
	color: white;
	font-size: 0.75rem;
	border-radius: 8px;
	border: none;
	cursor: pointer;
	transition: background-color 0.2s;
}

.change-password-button:hover {
	background-color: #75b23f;
}

/* 
	EMAIL VERIFICATION 
	==================
	Valkoinen "kortti", samaa tyyliä kuin login/register-lomakkeet
  */
.email-verification-container {
	width: 400px;
	max-width: 90%;
	background-color: #fafafa;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 5px 10px 20px 5px rgba(0, 0, 0, 0.39);
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10vh;
	margin-bottom: 20px;
	text-align: center;
}

/* Eri tilojen värit */
.email-verification-container.loading-state,
.email-verification-container.success-state,
.email-verification-container.error-state {
	color: #505050;
}

.email-verification-title {
	font-size: 1rem;
	font-weight: 700;
	margin-bottom: 10px;
	color: #505050;
}

.email-verification-text {
	font-size: 0.85rem;
	margin: 10px 0;
}

.email-verification-btn {
	background-color: #7fc646;
	color: #fff;
	border-radius: 8px;
	padding: 8px 20px;
	border: none;
	cursor: pointer;
	transition: background-color 0.2s;
	font-size: 0.75rem;
	margin-top: 20px;
	text-transform: uppercase;
}

.email-verification-btn:hover {
	background-color: #75b23f;
}
