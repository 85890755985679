:root {
	--nav-width--hover: 230px;
	--first-color: #7fc646;
	--first-color-light: #505050;
	--white-color: #f7f6fb;
	--third-color: #fead2d;
	--body-font: "Poppins";
	--normal-font-size: 1rem;
}

body {
	margin: var(--header-height) 0 0 0;
	font-family: var(--body-font);
	font-size: var(--normal-font-size);
	transition: 0.5s;
}

a {
	text-decoration: none;
}

b {
	bottom: 0;
}

.l-navbar {
	font-family: "poppins";
	font-size: 1.25rem;
	text-decoration: none;
	position: fixed;
	top: 0;
	text-decoration: none;
	width: 90px;
	height: 100vh;
	background-color: var(--third-color);
	transition: 0.5s;
	z-index: 100;
	box-shadow: 1px 1px 5px rgba(0 0 0 / 20%);
	overflow: hidden;
}

.l-navbar:hover {
	width: var(--nav-width--hover);
	z-index: 100;
}

.nav {
	height: calc(100% - 73px);
	display: grid;
	grid-template: repeat(2, 1fr) / 1fr;
	justify-content: space-between;
	align-content: center;
	overflow: hidden;
}

.lang_dropdown {
	margin: 0;
	padding: 0;
	background-color: #00000000;
	border: none;
	flex-direction: row;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.nav_link {
	text-decoration: none;
	display: grid;
	grid-column: 1;
	grid-row: 1;
	grid-template-columns: max-content max-content;
	align-items: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.nav_list {
	grid-column: 1;
	grid-row: 1;
	padding-top: 23px;
}

.nav_logo {
	grid-row: 1 / 1;
	margin-bottom: 2rem;
}

.nav_logo-icon {
	font-size: 1.25rem;
	color: var(--white-color);
	padding-left: 1.5rem;
}

.nav_logo-name {
	color: var(--first-color);
	font-weight: 700;
	padding-left: 1.5rem;
}

.nav_name {
	padding-left: 28px;
	/* font-weight: 900; */
}

.nav_link {
	position: relative;
	color: #202020;
	font-weight: 500;
	margin-bottom: 1rem;
	transition: 0.3s;
	list-style: none;
}

.nav_link:hover {
	color: var(--first-color);
}

.nav_link img {
	transition:
		transform 0.3s ease,
		filter 0.3s ease;
}

.nav_link:hover img {
	filter: brightness(0.55) sepia(1) saturate(20) hue-rotate(60deg);
	transform: rotateY(190deg);
	transition: transform 0.3s ease;
}

.nav_link_dropdown {
	position: relative;
	color: var(--first-color-light);
	margin-bottom: 1.5rem;
	transition-duration: 1s;
}

.nav_link_dropdown_content {
	display: none;
	position: relative;
	color: var(--first-color-light);
	margin-bottom: 1.5rem;
	transition: 0.3s;
	transition-delay: 2s;
	height: 70px;
}

.nav_link_dropdown_content {
	display: block;
	transition-duration: 1s;
}

.nav_header {
	height: 70px;
}

.nav_icon {
	font-size: 1.25rem;
	filter: brightness(1) sepia(1) saturate(15) hue-rotate(100deg);
}

.show {
	left: 0;
}

.body-pd {
	padding-left: calc(var(--nav-width) + 1rem);
}

.active {
	color: var(--white-color);
	background-color: var(--third-color);
}

/* .active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: black;
} */

.height-100 {
	height: 100vh;
}

.currentRC {
	flex-direction: column;
	text-overflow: ellipsis;
	display: block;
	color: #505050;
	margin-top: 5px;
	margin-left: 18px;
	font-size: 0.8rem;
	font: "Poppins";
	overflow: hidden;
	word-break: break-word;
	width: 70px;
	hyphens: auto;
	height: 57px;
}

.nav_bottom_links {
	grid-row: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.nav_link_chat_and_notes {
	position: relative;
	color: white;
	margin-bottom: 22px;
	transition: 0.3s;
	list-style: none;
	padding-left: 21.5px;
	display: inline;
	cursor: pointer;
}

.nav_link_chat_and_notes:hover {
	color: var(--first-color);
}

.help_and_support {
	display: inline;
	cursor: pointer;
}

.helpAndSupportText {
	font-size: 20px;
	margin-left: 25px;
	display: inline-block;
}

.nav_link_contacts {
	position: relative;
	color: white;
	margin-bottom: 22px;
	transition: 0.3s;
	list-style: none;
	padding-left: 21.5px;
	display: flex; /* Muutettu inline -> flex */
	align-items: center; /* Kohdista sisältö pystysuunnassa keskelle */
	cursor: pointer;
}

.contacts {
	display: flex; /* Muutettu inline -> flex */
	align-items: center; /* Kohdista sisältö pystysuunnassa keskelle */
	cursor: pointer;
}

.contact_text {
	font-size: 20px;
	margin-left: 25px;
	padding-top: 8px;
	display: inline-block; /* inline -> inline-block */
}

.nav_link_contacts:hover {
	color: var(--first-color);
}
@media screen and (min-width: 700px) {
	body {
		margin: calc(var(--header-height) + 1rem) 0 0 0;
	}

	.l-navbar {
		left: 0;
		/* padding: 1rem 1rem 0 0 */
	}

	.show {
		width: calc(var(--nav-width) + 156px);
	}

	.body-pd {
		padding-left: calc(var(--nav-width) + 188px);
	}
}

@media screen and (min-height: 758px) {
	.nav-logo {
		margin: 0;
	}
}
