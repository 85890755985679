.selectRoadCooperative-page {
	width: 100vw;
	height: 100vh;
	background-color: #fead2d;
	background-image: url("../../resources/common/verkkopalvelu-footer.svg");
	background-repeat: no-repeat;
	background-position: bottom center;
	background-attachment: fixed;
	background-size: 100% auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	overflow: auto;
}
