a {
	/* color: rgb(255, 255, 255); */
	display: inline-block;
	font-weight: 400;
	text-decoration: lightgreen;
	transition-duration: 0.5s;
}

.grid-topNavBar {
	grid-column: 1 / 3;
	grid-row: 1 / 2;
	overflow: hidden;
	position: relative;
	min-height: 50px;
	height: 50px;
}

.top-nav-dropdown {
	align-items: center;
	justify-content: center;
	min-height: 50px;
	height: 50px;
	position: fixed;
	background-color: var(--field-color);
	z-index: 1000;
	box-shadow: 2px 3px 12px 2px rgb(0 0 0 / 31%);
}

.top-nav-header {
	display: flex;
	flex-direction: row;
}

.top-nav-dropdown-content {
	position: absolute;
	width: 100vw;
	background-color: var(--field-color);
	align-items: center;
	/* justify-content: center; */
	padding-left: 28px;
	z-index: 2000;
	padding-top: 2vh;
}

.top-nav-grid {
	list-style: none;
	text-decoration: none;
	display: grid;
	grid-template: repeat(10, 1fr) / auto;
	height: 100vh;
	width: 100vw;
	position: absolute;
	position: fixed;
	background-color: var(--third-color);
	overflow: visible;
	z-index: 10000;
	align-items: center;
	justify-items: start;
	font-size: 1.7rem;
	font-family: "Poppins", sans-serif;
	transition-duration: 0.3s;
}

.top-nav-logo {
	display: flex;
	width: 100vw;
	justify-content: center;
	min-height: 50px;
	height: 50px;
}

.burger {
	position: absolute;
	left: 0;
	padding-left: 25px;
	padding-top: 12px;
	transition: all 0.3s ease;
}

.burger div {
	padding-left: 20px;
	width: 12px;
	height: 3px;
	color: var(--rock-color);
	background-color: var(--rock-color);
	margin: 5px;
	z-index: 10005;
	transition: all 0.3s ease;
}

.top-nav-link-logo {
	grid-column: 1;
	grid-row: 1;
	color: #151515;
}

.top-nav-link-text {
	grid-column: 2;
	grid-row: 1;
	padding-left: 20px;
	color: #151515;
}

.top-nav-link-1 {
	grid-row: 1;
	display: grid;
}

.top-nav-link-2 {
	grid-row: 2;
	display: grid;
}

.top-nav-link-3 {
	grid-row: 3;
	display: grid;
}

.top-nav-link-4 {
	grid-row: 4;
	display: grid;
}

.top-nav-link-5 {
	grid-row: 5;
	display: grid;
}

.top-nav-link-6 {
	grid-row: 6;
	display: grid;
}

.top-nav-link-7 {
	grid-row: 7;
	display: grid;
}

.top-nav-link-8 {
	grid-row: 8;
	display: grid;
}

.top-nav-link-9 {
	grid-row: 9;
	display: grid;
}

.top-nav-link-10 {
	grid-row: 10;
	display: grid;
}

.top-nav-dropdown-content {
	transform: translateX(-100%);
}

.top-nav-dropdown-content-active {
	transform: translateX(0%);
}

.toggle .burger-line-1 {
	transform: rotate(-45deg) translate(-7px, 5px);
	color: #151515;
	background-color: #151515;
}

.toggle .burger-line-2 {
	opacity: 0;
	color: #151515;
	background-color: #151515;
}

.toggle .burger-line-3 {
	transform: rotate(45deg) translate(-7px, -6px);
	color: #151515;
	background-color: #151515;
}

.helpSupport {
	color: #151515;
	display: row;
}

.top-nav-link-text-ChatBotSupport {
	color: #151515;
	margin-left: 18px;
	font-size: 1.7rem;
}
