* {
	font-family: "poppins";
}

.maplibregl-ctrl-top-left {
	pointer-events: all;
}

.marker {
	font-size: 14px;
	color: rgb(16 16 16);
	text-align: center;
	background-color: rgb(253 253 253 / 78%);
	border-radius: 5px;
	padding: 10px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.map-container {
	overflow-y: hidden;
	overflow-x: hidden;
	border-radius: 10px;
	border: solid #50505030;
	border-width: 1px;
	box-shadow: 1px 1px 5px rgba(0 0 0 / 20%);
	height: 100%;
	position: relative;
}

.map {
	height: 100%;
	right: 0;
	object-fit: contain;
	overflow: hidden;
}

.map-property-info-popup .maplibregl-popup-content {
	max-width: 330px;
	background-color: #fff;
	border-radius: 10px;
	padding: 10px;
	padding-top: 15px;
}

.text-center {
	text-align: center;
}

.small-font {
	font-size: 0.8rem;
}

.map-property-info-popup-div {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	color: #505050;
	justify-content: space-between;
	height: 100%;
}

.maplibregl-popup-close-button {
	font-size: 1.5rem;
	top: -5px;
}

.map-property-info-popup-button {
	letter-spacing: 0.13em;
	color: black;
	width: 100%;
	height: 32px;
	font-family: "Poppins";
	font-size: 0.8rem;
	border: none;
	background-color: var(--forest-color);
	color: white;
	border-radius: 10px;
	cursor: pointer;
	transition-duration: 00.15s;
}

.map-property-info-popup-content {
	max-height: 220px;
	overflow-y: auto;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 5px;
}

.map-property-info-popup-button:hover {
	background-color: #4ca239;
}

.map-property-info-popup-title {
	font-size: 1rem;
	cursor: copy;
}

p.map-property-info-popup-div {
	font-size: 1rem;
}

.map-property-info-popup-property {
	border-bottom: solid 1px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 5px;
}

.map-search-box {
	position: absolute;

	right: 0px;
	margin: 10px;
}

.map-search-input-layout {
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.159);
	padding: 1rem;
	border-radius: 10px 10px 0 0;
}

.map-search-input-field {
	outline: none;
	width: 200px;
	border-radius: 5px;
	border-width: 1px;
	border-color: #50505090;
}

.active-search {
	transform: translateX(0%);
	transition-duration: 0.3s;
	background-color: #00000029;
	padding: 1rem;
	padding-top: 0;
	border-radius: 0 0 10px 10px;
	margin-right: 10px;
}

.map-search-content {
	transform: translateX(150%);
	transition-duration: 0.3s;
	background-color: #00000029;
	padding: 1rem;
	padding-top: 0;
	border-radius: 0 0 10px 10px;
	margin-right: 10px;
}

.map-button-wrapper {
	text-align: center;
}

.map-casual-button {
	width: 50%;
	height: 38px;
	background-color: #7fc646;
	border-width: 0;
	color: white;
	border-radius: 5px;
	transition-duration: 500ms;
	cursor: pointer;
}

.search-casual-button {
	width: 50%;
	height: 25px;
	background-color: #7fc646;
	border-width: 0;
	color: white;
	border-radius: 5px;
	transition-duration: 500ms;
	cursor: pointer;
}

.map-casual-button:hover {
	color: #505050;
	transition-duration: 500ms;
}

.map-dropdown {
	position: absolute;
	margin-top: 10px;
	margin-left: 10px;
	margin-bottom: 0;
	font-size: 1rem;
	border-radius: 5px;
	border-top-left-radius: 20px;
	overflow: hidden;
}

.map-dropdown-mobile {
	position: absolute;
	margin-top: 10px;
	margin-left: 10px;
	margin-bottom: 0;
	z-index: 160;
	font-size: 1rem;
	border-radius: 5px;
	border-top-left-radius: 20px;
}

.map-dropdown-te {
	background-color: rgba(0, 0, 0, 0.159);
	padding: 1rem;
	border-radius: 10px 10px 0 0;
	margin: 0;
}

.map-dropdown-arrow {
	margin-left: 5px;
	color: black;
	transition-duration: 0.7s;
}

.map-dropdown-content {
	transform: translateX(-120%);
	transition-duration: 0.3s;
	background-color: #00000029;
	padding: 1rem;
	padding-top: 0;
	border-radius: 0 0 10px 10px;
}

.map-dropdown:hover .map-dropdown-content {
	display: block;
	transition-duration: 0.5s;
	transform: translateX(0%);
}

.map-dropdown:hover .map-dropdown-arrow {
	transform: rotate(900deg);
}

.map-dropdown-content-visible {
	display: block;
	transition-duration: 0.5s;
	transform: translateX(0%);
}

.map-dropdown-arrow-spinning {
	transform: rotate(900deg);
}

.vayla-content {
	position: absolute;
	right: 10px;
	top: 10px;
	margin-top: 10px;
	background-color: var(--third-color);
	height: 200px;
	width: 200px;
}

.map-features-block {
	background-color: var(--forest-color);
	color: #fff;
	position: absolute;
	top: 10vh;
	border-radius: 15px;
	padding: 5px;
	margin-left: 2vh;
}

.maplibregl-popup-content {
	background-color: "#fafafa";
	font-size: 1rem;
	box-shadow: 5px 5px 10px 5px rgb(0 0 0 / 12%);
	z-index: 1000;
}

.mapboxgl-popup-content {
	background-color: "#fff";
	color: "#000";
}

.maplibregl-popup-tip {
	border-color: "#FF000099";
}

.maplibregl-popup-marker {
	background-color: var(--forest-color);
	z-index: 100000;
}

.mapboxgl-ctrl-attrib-button {
	/* display: none; */
	height: 30px;

	width: 30px;
}

.mapbox-gl-draw_ctrl-draw-btn {
	height: 40px;
	width: 40px;
	border-width: 1px;
	border-radius: 2px;
}

.mapbox-gl-draw_uncombine {
	display: none;
}

.mapbox-gl-draw_combine {
	display: none;
}

@media screen and (max-width: 1042px) {
	.map {
		/* height: 60vh; */
	}
}

@media screen and (max-width: 768px) {
	.Toastify__toast-container {
		bottom: 10px;
	}

	.map-dropdown-mobile {
		padding: 5px;
		font-size: 0.9rem;
		margin: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 20px;
	}

	.map-dropdown-mobile h4 {
		font-size: 1.2rem;
	}
}

.icon-container {
	position: absolute;
	right: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition:
		transform 0.3s ease,
		width 1s ease,
		height 1s ease,
		opacity 1s ease;
}

.icon-image {
	border-radius: 50%;
	background-color: white;
	border: 1px solid grey;
	transition:
		transform 0.3s ease,
		width 1s ease,
		height 1s ease,
		opacity 1s ease;
}
